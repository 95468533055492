/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

/* * {
    font-family: 'Open Sans', sans-serif;
    font-size: 99%;
} */

* {
  font-family: "Cairo", sans-serif;
  font-size: 99%;
}

.form-select,
.form-control {
  font-size: 99% !important;
  height: 37px;
  border-radius: 4px !important;
}

.form-search input {
  border-radius: 4px 0 0 4px !important;
}

.form-search button {
  border-radius: 0 4px 4px 0 !important;
}

i {
  font-size: 100%;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.ant-col {
  position: absolute !important;
  right: 0px !important;
}

@media only screen and (max-width: 600px) {
  .res-scheduler {
    width: 16rem !important;
  }

  .max-scheduler {
    width: 16rem !important;
  }
}

@media only screen and (min-width: 600px) {
  .res-scheduler {
    width: 32rem !important;
  }

  .max-scheduler {
    width: 32rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .res-scheduler {
    width: 46rem !important;
  }

  .max-scheduler {
    width: 46rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .res-scheduler {
    width: 50rem !important;
  }

  .max-scheduler {
    width: 50rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .res-scheduler {
    width: 53rem !important;
  }

  .max-scheduler {
    width: 63rem !important;
  }

  .scheduler-res {
    max-width: 53rem !important;
  }
}

.ant-popover-inner {
  z-index: 100 !important;
}

.pulse-button {
  display: block;
  background: #cca92c;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
}

.bg_button_tracking {
  background: #5ee75e;
  color: white;
}

b {
  font-weight: 600;
}

label {
  margin-bottom: 2px;
}

.modal-content {
  border-radius: 20px;
  border: none;
}

button {
  border-radius: 5px !important;
  border: none;
}

.table-radius {
  border-radius: 11px !important;
}

.btn-success {
  background: #77971f !important;
  color: #fff;
}

.btn-success:hover {
  background: #587f05 !important;
  color: #fff;
}

.btn-success:focus,
.btn-success:active {
  background: #4b6b07 !important;
}

.btn-success:disabled {
  background: #9ec250 !important;
}

.btn-primary-2 {
  background: #0d82ff !important;
  color: #fff;
}

.btn-primary-2:hover {
  background: #0067d5 !important;
  color: #fff;
}

.btn-primary-2:focus,
.btn-primary-2:active {
  background: #0067d5 !important;
}

.btn-primary-2:disabled {
  background: #1b7de7 !important;
}

.text-success {
  color: #77971f !important;
}

.text-success:hover {
  color: #587f05 !important;
}

.text-success:focus,
.text-success:active {
  color: #4b6b07 !important;
}

.text-success:disabled {
  color: #9ec250 !important;
}

.text-green {
  color: rgb(3, 146, 3) !important;
}

.bg-primary-2 {
  background: #0d82ff !important;
  color: #fff !important;
}

.bg-success-2 {
  background: #77971f !important;
  color: #fff !important;
}

.bg-secondary-2 {
  background: #515151 !important;
  color: #fff !important;
}

.btn-success,
.btn-primary,
.btn-primary-2,
.btn-warning,
.btn-info,
.btn-secondary,
.btn-danger,
.btn-dark {
  border: none;
}

.rbt-input-hint {
  display: none !important;
}

.rbt-input-main {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rbt-button-input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.div-inventory-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 100%;
  height: 110px;
  overflow: hidden;
  border-radius: 10px;
  padding: 10px;
}

.div-inventory-photo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.add {
  font-size: 32px;
}

.radius-top-10px {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Color de fondo oscuro */
  backdrop-filter: blur(10px); /* Desenfoque del fondo */
  z-index: 999; /* Asegúrate de que esté por encima del modal */
}

input[type="search"] {
  padding-left: 32px;
  background-image: url("/search-icon.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 32px 20px;
}

.form-phone-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-phone-text {
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 12px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #444;
  outline: none;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.form-phone-text:disabled {
  cursor: not-allowed;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
}

.carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.8);
}

._loading_overlay_overlay {
  background: none !important;
  color: #000 !important;
  font-weight: 600 !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
